// hooks/useFetchOrder.ts
import { useState } from 'react';
import { idGar, searchGar } from '../services/api';
import { Order } from '../types/types';
import { useSchedule } from '../context/ScheduleContext';

const getCpfOrCnpjType = (value: string) => {
  const cleanedValue = value.replace(/\D/g, '');
  if (cleanedValue.length === 11) return 'cpf';
  if (cleanedValue.length === 14) return 'cnpj';
  return null;
};

export const useFetchOrder = () => {
  const [order, setOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const { setData } = useSchedule()

  const fetchOrder = async (searchValue: string) => {
    setLoading(true);
    setError(undefined);

    try {
      const urlParams = new URLSearchParams(window.location.search);
      const orderId = urlParams.get('order_id');

      if (orderId) {
        setData(prevState => ({
          ...prevState,
          pedidoEcommerce: orderId
        }));
        const response = await searchGar.get('/', { params: { pedidoEcommerce: orderId } });
        setOrder(response.data);
      } else {
        if (!searchValue) {
          setError('Por favor, forneça um valor válido');
          return;
        }

        const cpfOrCnpjType = getCpfOrCnpjType(searchValue);

        if (cpfOrCnpjType) {
          const response = await searchGar.get('/', { params: { [cpfOrCnpjType]: searchValue } });
          setOrder(response.data);
        } else {
          const response = await idGar.get('/', { params: { idGar: searchValue } });
          if (response.data.codigo === 801) {
            setError(response.data.mensagem);
            return;
          }
          const ecommerceOrder = response.data.pedidoEcommerce;

          if (ecommerceOrder) {
            const searchGarId = await searchGar.get('/', { params: { pedidoEcommerce: ecommerceOrder } });
            setData(prevState => ({
              ...prevState,
              pedidoEcommerce: ecommerceOrder
            }));
            setOrder(searchGarId.data);
          }
        }
      }
    } catch (error) {
      setError('Erro ao buscar pedidos. Tente novamente.');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { order, loading, error, fetchOrder };
};
