import { useState, useEffect } from 'react';

interface UseFormatedCPFProps {
  cpf: string | undefined;
}

const formatCPF = (cpf: string | undefined) => {
  if (!cpf) return '';

  cpf = cpf.replace(/\D/g, '');

  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
};

const useFormattedCPF = ({cpf}: UseFormatedCPFProps) => {
  const [formattedCPF, setFormattedCPF] = useState('');

  useEffect(() => {
    setFormattedCPF(formatCPF(cpf));
  }, [cpf]);

  return formattedCPF;
};

export default useFormattedCPF;