import { useState } from 'react';
import { ButtonSend, Paragraph } from './styles';
import { useSchedule } from '../../../context/ScheduleContext';
import { toSchedule } from '../../../services/api';
import Loading from '../../Loading';

const ButtonSendData = () => {
  const { data } = useSchedule()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [schedule, setSchedule] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const isCompleted = data?.hora === '' || data?.hora === undefined

  const handleSchedule = () => {
    setLoading(true);
    const objectToCustomUrlParams = (obj: Record<string, any>) => {
      return Object.entries(obj)
        .map(([key, value]) => {
          let encodedValue = encodeURIComponent(String(value))
            .replace(/%20/g, '%20')
            .replace(/%2F/g, '/')
            .replace(/%2D/g, '-')
            .replace(/%3A/g, ':');
          return `${key}=${encodedValue}`;
        })
        .join('&');
    };

    const queryParams = objectToCustomUrlParams(data);
    toSchedule.get(`/?${queryParams}`)
      .then((response) => {
        if (response.status === 200) {
          setMessage(response.data.mensagem)
          setSchedule(true);
        } else {
          console.error('Dados de agenda não encontrados na resposta');
        }
      })
      .catch((error) => {
        console.error('Erro ao carregar os dados da agenda', error);
        setMessage('Erro ao realizar o agendamento.')
      }).finally(() => {
        setLoading(false);
      })
  }

  return (
    <>
      <ButtonSend onClick={handleSchedule} disabled={isCompleted || schedule}>Confirmar agendamento</ButtonSend>
      {isLoading && <Loading loading={isLoading} />}
      {message && (
        <Paragraph>{message}</Paragraph>
      )}
    </>
  )
}

export default ButtonSendData