import styled from 'styled-components';

export const FormPostalCode = styled.form`
	label {
		display: block;
		margin-bottom: 8px;
	}

	fieldset {
		border: none;
		display: flex;
		align-items: center;
		margin: 0 auto;
	}

	input {
		border: 1px solid var(--gray-400);
		width: 203px;
		height: 42px;
		border-radius: 4px;
		outline: none;
		text-indent: 10px;

		&::placeholder {
			color: var(--gray-400);
		}

		&:focus,
		&:focus-visible,
		&:active {
			border: 1px solid var(--ins-blue);
		}
	}

	a {
		color: var(--ins-blue);
		text-decoration: underline;
		font-weight: 700;
		font-size: 16px;
		margin: 0 30px;
	}

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 203px;
		height: 42px;
		border: 1px solid var(--ins-blue);
		border-radius: 8px;
		background-color: transparent;
		color: var(--ins-blue);
		font-weight: 700;
		transition: 0.3s;

		svg {
			margin-right: 8px;
		}

		&:hover {
			background-color: var(--ins-blue);
			color: var(--white);

			svg {
				path {
					fill: var(--white);
				}
			}
		}
	}
`;