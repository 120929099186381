import styled from 'styled-components';

interface Props {
  $isError?: boolean;
}

export const Card = styled.div`
  max-width: 324px;
  width: 100%;
  height: 312px;
  
  margin-right: 16px;
  border-radius: 8px;
  padding: 20px 16px;
  background-color: var(--white);
`

export const IconContent = styled.div`
  width: 36px;
  height: 30px;
  margin-bottom: 20px;
`

export const Icon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const Title = styled.h3`
  font-size: 22px;
  font-weight: 400;
  color: var(--black);

  margin-bottom: 10px;
`

export const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: var(--black);
  margin-bottom: 40px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  input {
    text-indent: 5px;
    height: 25px;
    border: none;
    border-bottom: 1px solid var(--black);
    margin-bottom: 30px;
    background-color: transparent;

    &:focus {
      outline: none;
    }
  }

  button {
    width: 100%;
    height: 42px;
    
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 8px;
    border: none;
    color: var(--white);
    transition: .4s;
    background-color: var(--ins-blue);

    &[disabled] {
      background-color: var(--gray-300);
      cursor: initial;
    }
  }
`
export const Container = styled.div`
  max-width: 1295px;
  margin: 48px auto 0;

  &.row {
    display: flex;
    align-items: center;
  }
`

export const ContentCol = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1295px;
  margin: 40px auto;
`

export const TitlePage = styled.h1`
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 20px;
`

export const Paragraph = styled.p<Props>`
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 20px;
  color: ${({ $isError }) => ($isError ? 'var(--feedback-negative)' : 'var(--gray-900)')};

  &.wordBreak {
    width: 75%;
  }
`