import styled from 'styled-components';

export const ContentCard = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 424px;
	width: 100%;
	min-height: 415px;
	background-color: var(--white);
	border-radius: 8px;
	padding:35px;
	margin-right: 14px;
	line-height: 22px;
`;

export const BoxIcon = styled.div`
	max-width: 56px;
	height: 56px;
	padding: 8px;
`

export const Icon = styled.img`
	max-width: 100%;
	height: 100%;
	object-fit: contain;
`

export const Title = styled.h2`
	font-size: 24px;
	font-weight: 400;
	color: var(--black);
	margin: 8px 0 22px;
`

export const TextCard = styled.p`
	color: var(--gray-900);
	font-weight: 400;
	font-size: 15px;
	padding: 0 5px;
	
	&.mt {
		margin-top: 20px;
	}

	&.mb {
		margin-bottom: 20px;
	}
`;

export const List = styled.ul`
  padding-left: 20px;
  list-style: disc;
`

export const ListItem = styled.li`
	color: var(--gray-800);
	position: relative;
`;

export const Button = styled.button`
	width: 100%;
	height: 42px;
	background-color: var(--ins-blue);
	color: var(--white);
	font-size: 16px;
	font-weight: 700;
	border: none;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s;
	margin-top: auto;

	&:hover {
		border: 1px solid var(--ins-blue);
		background-color: transparent;
		color: var(--ins-blue);
	}
`;
