import { FormPostalCode } from './styles'

interface PostalCodeFormProps {
  postalCode: string;
  handlePostalCodeChange: (value: string) => void;
  handlePostalCodeSearch: () => void;
}
const PostalCodeForm = ({ postalCode, handlePostalCodeChange, handlePostalCodeSearch }: PostalCodeFormProps) => {
  const formatPostalCode = (value: string) => {
    value = value.replace(/\D/g, '');
    if (value.length > 5) {
      value = value.replace(/^(\d{5})(\d{1,3})/, '$1-$2');
    }
    return value;
  };
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatPostalCode(e.target.value);
    handlePostalCodeChange(formattedValue);
  };

  return (
    <FormPostalCode onSubmit={handlePostalCodeSearch}>
      <label htmlFor='postalCode'>Digite o CEP</label>
      <fieldset>
        <input 
          type='text' 
          id='postalCode' 
          onChange={handleChange}
          placeholder='00000-000'
          value={postalCode}
          maxLength={9}
        />
        <a href="https://buscacepinter.correios.com.br/app/endereco/index.php" target='_blank' rel="noreferrer">Nao sei meu cep</a>
        <button type='button' onClick={handlePostalCodeSearch}>
          <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 6.5C13.5 7.93437 13.0344 9.25938 12.25 10.3344L16.2063 14.2937C16.5969 14.6844 16.5969 15.3188 16.2063 15.7094C15.8156 16.1 15.1812 16.1 14.7906 15.7094L10.8344 11.75C9.75938 12.5375 8.43437 13 7 13C3.40937 13 0.5 10.0906 0.5 6.5C0.5 2.90937 3.40937 0 7 0C10.5906 0 13.5 2.90937 13.5 6.5ZM7 11C7.59095 11 8.17611 10.8836 8.72208 10.6575C9.26804 10.4313 9.76412 10.0998 10.182 9.68198C10.5998 9.26412 10.9313 8.76804 11.1575 8.22208C11.3836 7.67611 11.5 7.09095 11.5 6.5C11.5 5.90905 11.3836 5.32389 11.1575 4.77792C10.9313 4.23196 10.5998 3.73588 10.182 3.31802C9.76412 2.90016 9.26804 2.56869 8.72208 2.34254C8.17611 2.1164 7.59095 2 7 2C6.40905 2 5.82389 2.1164 5.27792 2.34254C4.73196 2.56869 4.23588 2.90016 3.81802 3.31802C3.40016 3.73588 3.06869 4.23196 2.84254 4.77792C2.6164 5.32389 2.5 5.90905 2.5 6.5C2.5 7.09095 2.6164 7.67611 2.84254 8.22208C3.06869 8.76804 3.40016 9.26412 3.81802 9.68198C4.23588 10.0998 4.73196 10.4313 5.27792 10.6575C5.82389 10.8836 6.40905 11 7 11Z" fill="#593EFF"/>
          </svg>
          Buscar agendas
        </button>
      </fieldset>
    </FormPostalCode>
  )
}

export default PostalCodeForm;