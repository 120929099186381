import { useEffect, useState } from 'react';
import { Title, ContainerCol, Content, ContentCol, ButtonSearchType, LocalesList, LocalesListItem } from './styles';
import PostalCodeForm from '../../PostalCodeForm';
import usePostalCodeResult from '../../../../hooks/usePotalCodeResult';
import { Order } from '../../../../types/types';
import ScheduleCalendar from '../../ScheduleCalendar';
import { useSchedule } from '../../../../context/ScheduleContext'
import Loading from '../../../Loading';

interface ScheduleLocalesProps {
  order: Order | null;
  validationType: string
}

const ScheduleLocales = ({ order, validationType }: ScheduleLocalesProps) => {
  const [searchType, setSearchType] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [local, setLocal] = useState<number>();
  const { locales, isLoading, handlePostalCodeSearch } = usePostalCodeResult(postalCode, order?.id);
  const { setData } = useSchedule();

  const handleSearchType = (type: string) => {
    setSearchType(type);
  };

  const handleLocaleSelected = (type: number) => {
    setLocal(type);
  };

  useEffect(() => {
    if (local && locales) {
      const selectedLocale = locales.find(locale => locale.codigo === local);
      
      if (selectedLocale) {
        setData(prevState => ({
          ...prevState,
          idGar: order?.id,
          codigo: selectedLocale.codigo,
          nomeFantasia: selectedLocale.nomeFantasia,
          telefone: selectedLocale.telefone,
          endereco: selectedLocale.endereco,
          numero: selectedLocale.numero,
          complemento: selectedLocale.complemento || '',
          cidade: selectedLocale.cidade,
          uf: selectedLocale.uf,
          cep: selectedLocale.cep,
          bairro: selectedLocale.bairro,
        }));
      }
    }
  }, [local, locales,order, setData]);
  
  return (
    <ContainerCol>
      <Title>Informe sua localização para acessar as agendas</Title>
      <Content className='container-top'>
        <ButtonSearchType
          $isActive={searchType === 'postalCode' || searchType === ''}
          onClick={() => handleSearchType('postalCode')}
        >
          Buscar por CEP
        </ButtonSearchType>
      </Content>
      <ContentCol>
        {(searchType === 'postalCode' || searchType === '') && (
          <PostalCodeForm
            postalCode={postalCode}
            handlePostalCodeChange={setPostalCode}
            handlePostalCodeSearch={handlePostalCodeSearch}
          />
        )}
      </ContentCol>
      <Content>
        {isLoading && <Loading loading={isLoading} />}
        {locales && (
          <LocalesList>
            {locales
              .sort((a, b) => a.distance - b.distance)
              .map((locale) => (
                <LocalesListItem key={locale.codigo} $isSelected={locale.codigo === local} onClick={() => { handleLocaleSelected(locale.codigo) }}>
                  <p>{validationType === 'videoconference' ? 'Videoconferência' : 'Presencial'} - {locale.bairro} - {locale.cidade} - {locale.uf} - {locale.nomeFantasia} - {locale.endereco}, {locale.numero}</p>
                </LocalesListItem>
              ))}
          </LocalesList>
        )}
        {local && (
          <ScheduleCalendar orderId={order?.id} localCode={local} />
        )}
      </Content>
    </ContainerCol>
  );
};

export default ScheduleLocales;
