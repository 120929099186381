import React, { useState } from 'react';
import { searchJobs } from '../services/api';
import { ArProps } from '../types/types';

interface UsePostalCodeResult {
  locales: ArProps[];
  isLoading: boolean;
  handlePostalCodeSearch: (e?: React.FormEvent) => Promise<void>;
}

const usePostalCodeResult = (postalCode: string, orderId: string | undefined): UsePostalCodeResult => {
  const [locales, setLocales] = useState<ArProps[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const handlePostalCodeSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!postalCode || !orderId) return;

    setLoading(true);
    try {
      const response = await searchJobs.get(`?idGar=${orderId}&cnhOpt=true&cep=${postalCode.replace('-', '')}`);
      if (response.status === 200 && response.data === '') {
        console.error('Erro ao buscar o CEP, tente novamente.');
      }
      setLocales(response.data);
    } catch (error) {
      console.error('Erro ao buscar locais:', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    locales,
    isLoading,
    handlePostalCodeSearch,
  };
};

export default usePostalCodeResult;
