import styled from 'styled-components';

export const Container = styled.div`
	max-width: 1295px;
	margin: 48px auto 0;
`;

export const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 48px;
`;

export const ContentPaginate = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-left: 30px;
	width: 200px;
`;

export const TitlePage = styled.h1`
	font-size: 32px;
	font-weight: 400;
	margin-bottom: 20px;
`;

export const Paragraph = styled.p`
	font-size: 18px;
	font-weight: 400;
	margin-bottom: 20px;

	&.wordBreak {
		width: 75%;
	}
`;

export const Table = styled.table`
	max-width: 968px;
	width: 100%;
	border-collapse: separate;
	border-spacing: 0 10px;

	.tableHead {
		min-width: 20px;
		text-align: left;
	}

	.tableRow {
		border-radius: 8px;
		background-color: var(--white);
		&.selected {
			.tableLine {
				background-color: var(--ins-ice);

				&:first-of-type:after {
					content: '';
					position: absolute;
					width: 10px;
					height: 10px;
					background-color: var(--ins-blue);
					border-radius: 50%;
				}
			}
		}
	}

	.tableLine {
		cursor: pointer;
		padding: 10px 20px 10px 0;
		min-height: 65px;
		transition: 0.4s;
		border-top: 1px solid var(--gray-500);
		border-bottom: 1px solid var(--gray-500);
		position: relative;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: pre-wrap;

		&:first-of-type {
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			border-left: 1px solid var(--gray-500);
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;

			&::before {
				content: '';
				width: 8px;
				height: 100%;
				display: block;
				width: 16px;
				height: 16px;
				border: 1px solid var(--gray-500);
				border-radius: 50%;
			}
		}

		&:last-of-type {
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			border-right: 1px solid var(--gray-500);
		}

		&.tableName {
			max-width: 200px;
		}
	}
`;

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px;
	width: 203px;
	height: 42px;
	font-size: 18px;
	border-radius: 8px;
	border: none;
	background-color: var(--ins-blue);
	text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
	color: var(--white);
`;

export const ButtonPaginate = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--ins-blue);
	border-radius: 8px;
	border: none;
	width: auto;
	padding: 12px;
	cursor: pointer;

	&[disabled] {
		cursor: auto;
	}
`;

export const Image = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`;
