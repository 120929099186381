/* eslint-disable react-hooks/rules-of-hooks */
import { Fragment, useState } from 'react';
import ArrowLeft from '../../assets/icons/arrowLeft.svg';
import ArrowRight from '../../assets/icons/arrowRight.svg';
import { Container, Content, ContentPaginate, TitlePage, Paragraph, Table, Button, ButtonPaginate, Image } from './styles';
import useFormattedCPF from '../../hooks/useFormatedCpf';
import useFormattedCNPJ from '../../hooks/useFormattedCNPJ';
import { Order } from '../../types/types';
import { useSchedule } from '../../context/ScheduleContext'
import { useEcommerceOrder } from '../../hooks/useEcommerceOrder';

interface OrderProps {
  orders: Order[];
  onSchedule: (selectedOrder: Order | null) => void;
}

const ITEMS_PER_PAGE = 5;

export const SelectOrder = ({ orders, onSchedule }: OrderProps) => {
  const [selectedOrder, setSelectedOrder] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { setData } = useSchedule();
  useEcommerceOrder(selectedOrder, setData);

  const flatOrders = orders.flat();
  const totalPages = Math.ceil(flatOrders.length / ITEMS_PER_PAGE);
  
  const sortedOrders = flatOrders.sort((a, b) => {
    const dateA = new Date(a.data);
    const dateB = new Date(b.data);
    return dateB.getTime() - dateA.getTime();
  });

  const formattedOrders = sortedOrders.map(order => {
    const formattedCpfCnpj = order.cpf
      ? useFormattedCPF({ cpf: order.historicoPessoaFisica.cpf })
      : useFormattedCNPJ({ cnpj: order.historicoPessoaJuridica.cnpj });

    return { ...order, formattedCpfCnpj };
  });

  const handleSelectOrder = (id: string) => {
    setSelectedOrder(id);
  }

  const handleScheduleClick = () => {
    const order = formattedOrders.find(order => order.id === selectedOrder);
    onSchedule(order || null);
  }

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;

  const paginatedOrders = formattedOrders.slice(startIndex, endIndex);

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  }

  const isLastPage = currentPage === totalPages;
  const isFirstPage = currentPage === 1;

  return (
    <Fragment>
      <Container>
        <TitlePage>Agendamento da validação</TitlePage>
        <Paragraph>Selecione o produto para o qual você deseja agendar a validação.</Paragraph>
      </Container>
      <Container>
        <Table>
          <thead>
            <tr>
              <th className='tableHead'></th>
              <th className='tableHead'>Pedido</th>
              <th className='tableHead'>CPF/CNPJ</th>
              <th className='tableHead'>Produto</th>
              <th className='tableHead'>Titular</th>
            </tr>
          </thead>
          <tbody>
            {paginatedOrders.map((order) => (
              <tr
                className={`tableRow ${selectedOrder === order.id ? 'selected' : ''}`}
                key={order.id}
                onClick={() => handleSelectOrder(order.id)}
              >
                <td className='tableLine'></td>
                <td className='tableLine'>{order.id}</td>
                <td className='tableLine'>{order.formattedCpfCnpj}</td>
                <td className='tableLine tableName'>{order.produto.descricao}</td>
                <td className='tableLine'>{order.name ? order.name : order.email}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Content>
          <Button onClick={handleScheduleClick}>Agendar</Button>
          {totalPages > 1 && (
            <ContentPaginate>
              <ButtonPaginate disabled={isFirstPage} onClick={() => handlePageChange(currentPage - 1)}>
                <Image src={ArrowLeft} alt='Anterior' />
              </ButtonPaginate>
              <span>{`Página ${currentPage} de ${totalPages}`}</span>
              <ButtonPaginate disabled={isLastPage} onClick={() => handlePageChange(currentPage + 1)}>
                <Image src={ArrowRight} alt='Próximo' />
              </ButtonPaginate>
            </ContentPaginate>
          )}
        </Content>
      </Container>
    </Fragment>
  );
};
