/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useFetchOrder } from '../../hooks/useFetchOrder'
import { Fragment } from 'react/jsx-runtime'
import { GetOrders } from '../../components/GetOrders'
import { SelectOrder } from '../../components/SelectOrder'
import { ValidationService } from '../../components/ValidationService'
import { Order } from '../../types/types'
import Loading from '../../components/Loading'

export const Scheduling = () => {
  const { order, loading, error, fetchOrder } = useFetchOrder();
  const [step, setStep] = useState(1);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [urlHasOrderId, setUrlHasOrderId] = useState<boolean>(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const orderId = urlParams.get('order_id');
    if (orderId) {
      setUrlHasOrderId(true);
      fetchOrder(orderId);
    }
  }, []);

  useEffect(() => {
    if (order) {
      setStep(2);
      setUrlHasOrderId(false);
    }
  }, [order]);

  const handleSchedule = (selectedOrder: Order | null) => {
    if (selectedOrder) {
      setSelectedOrder(selectedOrder);
      setStep(3);
    } else {
      console.error('Nenhum pedido selecionado.');
    }
  };

  const renderStep = () => {
    if (urlHasOrderId && loading) {
      return <Loading loading={loading} />;
    }

    switch (step) {
      case 1:
        return <GetOrders fetchOrder={fetchOrder} error={error} />;
      case 2:
        return <SelectOrder orders={order ? [order] : []} onSchedule={handleSchedule} />;
      case 3:
        return <ValidationService order={selectedOrder} />;
      default: return null;
    }
  }

  return (
    <Fragment>
      {renderStep()}
    </Fragment>
  )
}