import { useState } from 'react'
import ScheduleType from './Steps/ScheduleType';
import ScheduleLocales from './Steps/ScheduleLocales';
import PopUp from './PopUp'
import useFormattedCPF from '../../hooks/useFormatedCpf';
import useFormattedCNPJ from '../../hooks/useFormattedCNPJ';
import { Order } from '../../types/types';
import ButtonSendData from './ButtonSendData';
import {
  Aside,
  Container,
  ButtonValidation,
  Subtitle,
  Paragraph,
  List,
  ListItem,
} from './styles'
interface ValidationServiceProps {
  order: Order | null;
}

export const ValidationService = ({ order }: ValidationServiceProps) => {
  const FormattedCpf = useFormattedCPF({ cpf: order?.historicoPessoaFisica.cpf });
  const FormattedCnpj = useFormattedCNPJ({ cnpj: order?.historicoPessoaJuridica?.cnpj });
  const [step, setStep] = useState<number>(1);
  const [isVideoConference, setIsVideoConference] = useState(false);
  const [validationType, setValidationType] = useState('');

  const handlePopVideoConference = () => {
    setIsVideoConference(!isVideoConference);
  }

  const handleValidationType = (type: string) => {
    setValidationType(type);
    setStep(2);
  }

  const renderStep = () => {
    const stepComponents: { [key: number]: JSX.Element } = {
      1: <ScheduleType onPopVideoConference={handlePopVideoConference} onPresential={() => handleValidationType('presential')} />,
      2: <ScheduleLocales order={order} validationType={validationType} />
    }
    return stepComponents[step] || null;
  }

  const renderValidationTypeSection = () => {
    return (
      <>
        <Paragraph className='validationType'>Tipo de atendimento:</Paragraph>
        <Subtitle>{validationType === 'videoconference' ? 'Videoconferência' : 'Presencial'}</Subtitle>
        <ButtonValidation onClick={() => { handleValidationType(''); setStep(1) }}>Alterar</ButtonValidation>
      </>
    )
  }

  return (
    <Container>
      <Aside>
        {validationType && renderValidationTypeSection()}
        <Paragraph>
          Agendando atendimento para:
        </Paragraph>
        <List>
          <ListItem><strong>{order?.produto.descricao}</strong></ListItem>
          <ListItem>{FormattedCpf && `CPF: ${FormattedCpf}`}</ListItem>
          <ListItem>{FormattedCnpj && `CNPJ: ${FormattedCnpj}`}</ListItem>
        </List>
        {validationType && (
          <ButtonSendData />
        )}
      </Aside>
      {isVideoConference && (
        <PopUp
          handlePopVideoConference={handlePopVideoConference}
          handleValidationType={handleValidationType} />
      )}
      {renderStep()}
    </Container>
  )
}