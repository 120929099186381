import axios from "axios";

export const idGar = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_ID_GAR_ROUTE}`
});

export const searchGar = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_SEARCH_GAR_ROUTE}`
});

export const searchJobs = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_SEARCH_JOBS_ROUTE}`
});

export const scheduleAvailability = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_SCHEDULE_AVAILABILITY_ROUTE}`
});

export const toSchedule = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_TO_SCHEDULE_ROUTE}`
});