import styled from 'styled-components';

interface Props {
	$isActive?: boolean;
	$isSelected?: boolean;
}

export const Content = styled.div<Props>`
	display: flex;
	position: relative;
	padding: 0 16px;
	background-color: var(--white);

	&.container-top {
		padding: 0;
	}
	
	@media (max-width: 1295px) {
		max-width: 100%;
	}
`;

export const ContainerCol = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 970px;
	width: 100%;
`

export const ContentCol = styled.div<Props>`
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 30px 16px;
	background-color: var(--white);
`;

export const Title = styled.h1`
	font-size: 32px;
	font-weight: 400;
	margin-bottom: 32px;
`;

export const ButtonSearchType = styled.button<Props>`
	width: 145px;
	height: 48px;
	border: none;
	background-color: ${({ $isActive }) =>
		$isActive ? 'var(--gray-100)' : 'var(--gray-50)'};
	border-bottom: ${({ $isActive }) =>
		$isActive ? '2px solid var(--ins-blue)' : 'none'};
	transition: 0.2s;
`;

export const LocalesList = styled.ul`
	width: 100%;
	max-width: 500px;
	max-height: 400px;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	padding-right: 10px;

	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--gray-600);
		height: 108px;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--gray-200);
	}
`;

export const LocalesListItem = styled.li<Props>`
	margin-bottom: 10px;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	height: 80px;
	background-color: var(--white);
	padding: 18px 16px 18px 45px;
	border: 1px solid ${({$isSelected}) => $isSelected ? 'var(--ins-blue)' : 'var(--gray-200)'};
	border-radius: 8px;
	cursor: pointer;
	transition: 0.2s;

	&::before {
		content: '';
		position: absolute;
		top: ${({$isSelected}) => $isSelected ? '24px' : '25px'};
		left: ${({$isSelected}) => $isSelected ? '15px' : '16px'};
		width: 8px;
		height: 100%;
		display: block;
		width: 16px;
		height: 16px;
		border: ${({$isSelected}) => $isSelected ? '2px solid var(--ins-blue)' : '1px solid var(--gray-200)'};
		border-radius: 50%;
	}
	${({$isSelected}) => $isSelected ? 
		`&::after {
			content: '';
			position: absolute;
			top: 29px;
			left: 20px;
			width: 10px;
			height: 10px;
			background-color: var(--ins-blue);
			border-radius: 50%;
		}` : ''
	}
`;
