import styled, { createGlobalStyle, keyframes } from 'styled-components';

interface Props {
  $isLoaded?: boolean;
}

export const GlobalStyle = createGlobalStyle`
  :root {
    --white: #fff;
    --gray-50: #FAFAFA;
    --gray-100: #F5F5F5;
    --gray-200: #EEEEEE;
    --gray-300: #E0E0E0;
    --gray-400: #BDBDBD;
    --gray-500: #9E9E9E;
    --gray-600: #757575;
    --gray-700: #616161;
    --gray-800: #424242;
    --gray-900: #212121;
    --black: #000;


    --ins-blue: #593EFF;
    --ins-navy: #211858;
    --ins-gray: #8394B2;
    --ins-ice: #ECF1F9;

    --feedback-positive: #2F5711;
    --feedback-warning: #EDC843;
    --feedback-negative: #A8200D;

    --platform-green: #0FD297;
    --platform-darkGreen:#087662;
    --platform-lightGreen: #78FAA0;
    --platform-purple:#B47BFF;
    --platform-purpleLight:#E8A6FF;
    --platform-purpleDark:#7030C5;
    --platform-orange: #FF6D40;
    --platform-orangeLight: #FFB98C;
    --platform-orangeDark: #992400;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: Arial, Helvetica, sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 600;
  }

  button {
    cursor: pointer;
  }

  [disabled] {
     cursor: not-allowed;
  }
`

export const Main = styled.main`
  max-width: 100%;
  width: 100%;
  height: 100vh;
  background-color: var(--gray-50);
`
const skeletonLoading = keyframes`
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const ImageWithLoading = styled.img<Props>`
  width: 100%;
  height: 60%;
  object-fit: contain;
  display: block;
  animation: ${({ $isLoaded }) => ($isLoaded ? 'none' : skeletonLoading)} 1.5s linear infinite;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ $isLoaded }) => ($isLoaded ? 0 : 1)};
`;