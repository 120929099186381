import { useState, Fragment } from 'react'
import { Container, Card, Form, Icon, IconContent, Subtitle, Title, TitlePage, Paragraph, ContentCol } from './styles'
import cart from '../../assets/icons/cart.svg'
import user from '../../assets/icons/user.svg'
import useFormatter from '../../hooks/useFormatter'
import useOnlyNuber from '../../hooks/useOnlyNumbers'
import LoadingImg from '../../assets/icons/loading.svg'
import { ImageWithLoading } from '../../styles/global'

interface GetOrdersProps {
  fetchOrder: (searchValue: string) => void;
  error?: string;
}

export const GetOrders = ({ fetchOrder, error }: GetOrdersProps) => {
  const { garOrder, onlyNumber } = useOnlyNuber();
  const [value, handleChange] = useFormatter();
  const [loadingOrder, setLoadingOrder] = useState(false);
  const [loadingCpfCnpj, setLoadingCpfCnpj] = useState(false);

  const idGar = garOrder !== '';
  const document = value !== '';
  const fail = error !== ''
  const handleSearch = (event: React.FormEvent, field: 'garOrder' | 'document') => {
    event.preventDefault();
    const searchValue = (garOrder || value).replace(/\D/g, '');

    if (field === 'garOrder') {
      setLoadingOrder(true);
    } else {
      setLoadingCpfCnpj(true);
    }

    fetchOrder(searchValue);
  }

  return (
    <Fragment>
      <Container>
        <TitlePage>Agendamento da validação</TitlePage>
        <Paragraph className='wordBreak'>A validação é uma <strong>etapa obrigatória</strong>, porque é o momento em que a identidade do titular do certificado digital é confirmada. Somente após esse atendimento, o produto é entregue para a instalação.</Paragraph>
        <Paragraph>Para agendar a validação é necessário informar o <strong>número do pedido</strong> ou o <strong>CPF</strong> ou <strong>CNPJ</strong> do titular do certificado digital.</Paragraph>
      </Container>
      <Container className='row'>
        <Card>
          <IconContent>
            <Icon src={cart} alt='Icone' />
          </IconContent>
          <Title>Agendar pelo número do pedido</Title>
          <Subtitle>O número do pedido possui 8 digitos.</Subtitle>
          <Form onSubmit={(e) => handleSearch(e, 'garOrder')}>
            <input
              onChange={onlyNumber}
              type='text'
              placeholder='Digite o número do pedido'
              maxLength={8}
              value={garOrder}
            />
            <button
              disabled={!idGar || loadingOrder}
              type='button'
              onClick={garOrder !== '' ? (e) => handleSearch(e, 'garOrder') : undefined}>
                {loadingOrder ? <ImageWithLoading src={LoadingImg} alt='Loading' /> : 'Agendar'}
            </button>
          </Form>
        </Card>
        <Card>
          <IconContent>
            <Icon src={user} alt='Icone' />
          </IconContent>
          <Title>Agendar com o CPF ou CNPJ do titular</Title>
          <Subtitle>Digite somente os números</Subtitle>
          <Form onSubmit={(e) => handleSearch(e, 'document')}>
            <input
              onChange={(e) => handleChange(e.target.value)}
              type='text'
              placeholder='Digite CPF ou CNPJ do titular'
              maxLength={18}
              value={value}
            />
            <button
              disabled={!document || loadingCpfCnpj}
              type='button'
              onClick={value !== '' ? (e) => handleSearch(e, 'document') : undefined}>
                {loadingCpfCnpj ? <ImageWithLoading src={LoadingImg} alt='Loading' /> : 'Agendar'}
            </button>
          </Form>
        </Card>
      </Container>
      <ContentCol>
        <Paragraph $isError={fail}>{error && error}</Paragraph>
      </ContentCol>
    </Fragment>
  );
};  