import { ContentCard, BoxIcon, Icon, Button, Title, TextCard, List, ListItem } from './styles';
import { CardProps } from '../../types/types';

const Card: React.FC<CardProps> = ({ icon, title, textTop, textBottom, textInfo, buttonText, onClick }) => {
  return (
    <ContentCard>
      <BoxIcon>
        <Icon src={icon} alt={title} />
      </BoxIcon>
      <Title>{title}</Title>
      <TextCard>{textTop}</TextCard>
      {textInfo && (
        <List>
          <ListItem>{textInfo}</ListItem>
        </List>
      )}
      <TextCard className='mt mb'>{textBottom}</TextCard>
      <Button onClick={onClick}>{buttonText}</Button>
    </ContentCard>
  )
}

export default Card;