import styled from 'styled-components';

export const TitlePage = styled.h1`
	font-size: 32px;
	font-weight: 400;
	margin-bottom: 13px;
`;

export const Text = styled.p`
	font-size: 14px;
	font-weight: 400;
	color: var(--black);
	margin-bottom: 24px;
`

export const Content = styled.div`
	display: flex;
	align-items: center;
	position: relative;
`;

export const ContainerCol = styled.div`
	display: flex;
	flex-direction: column;
`